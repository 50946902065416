import { Check, Plus } from "components/icons";
import Button from "components/ui/Button";
import { CrossbeamRedirectModal } from "components/ui/CrossbeamRedirectModal";
import { CellProps } from "components/ui/data-grid/utils";
import { T } from "components/ui/Typography";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { SuggestedPartnerSource } from "models/SuggestedPartner";
import { parse, stringify } from "query-string";
import { useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  selectActivePayingFeatures,
  selectAllPartnerships,
  selectCompanyIdFromPartnerships,
} from "redux/api/selectors";
import { AvailableDirectoryColumns } from "redux/directory/defaults";
import { selectDirectoryView } from "redux/directory/selectors";
import { DirectoryToggleFilterType } from "redux/directory/types";
import { selectActivePartnerships } from "redux/init/selectors";
import { SentFromURL } from "screens/Frontoffice/shared/components/InviteForm/types";
import { DirectoryEvent } from "tracking";

const DIRECTORY_COMPANY_SOURCE_TO_SUGGESTION_SOURCE = {
  [DirectoryToggleFilterType.IsInCrm]: [SuggestedPartnerSource.Crm],
  [DirectoryToggleFilterType.IsRecommended]: [
    SuggestedPartnerSource.HighPotentialOverlap,
  ],
  [DirectoryToggleFilterType.IsYouMayKnow]: [
    SuggestedPartnerSource.Manual,
    SuggestedPartnerSource.ManualMirror,
    SuggestedPartnerSource.CrmMirror,
    SuggestedPartnerSource.PartnerList,
    SuggestedPartnerSource.PartnerListMirror,
  ],
};

export const PartnerCell = ({ row }: CellProps) => {
  const { classes } = useStyles();
  const { track } = useSegment();
  const history = useHistory();
  const location = useLocation();
  const { profile } = useUserProfile();
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const { companyIds } = useSelector(selectCompanyIdFromPartnerships);
  const allPartnerships = useSelector(selectAllPartnerships);
  const activePartnerships = useSelector(selectActivePartnerships);
  const { view } = useSelector(selectDirectoryView);
  const toggleFilterFieldname = view.filters.find(
    // there should be only one
    (item) =>
      (Object.values(DirectoryToggleFilterType) as string[]).includes(
        item.fieldname
      )
  )?.fieldname as DirectoryToggleFilterType | undefined;
  const {
    id,
    companyId,
    domainName,
    isInCrm,
    isRecommended,
    isYouMayKnow,
    suggestedPartners,
  }: {
    companyId: number;
    domainName: string;
    id: string;
    isInCrm: boolean;
    isRecommended: boolean;
    isYouMayKnow: boolean;
    suggestedPartners: { [key: string]: SuggestedPartnerSource };
  } = row;
  let hasPartnership = companyIds.includes(companyId);
  if (
    !companyId &&
    allPartnerships.some(
      (partnership) => partnership.requestedCompanyDomain === domainName
    )
  ) {
    hasPartnership = true;
  }
  const isPaidUser = profile.isPaidCustomer(payingFeatures);
  const hideInviteBtn =
    hasPartnership ||
    !profile.canInvitePartner(payingFeatures) ||
    companyId === profile.company.id;
  const isPartnerOnReveal = !!companyId;
  const isSuggestion = isInCrm || isRecommended || isYouMayKnow;
  let isActivePartnership = false;
  if (hasPartnership) {
    isActivePartnership = activePartnerships.some(
      (partnership) => partnership.getPartner(profile).id === companyId
    );
  }
  const [
    isCrossbeamRedirectModalOpen,
    setIsCrossbeamRedirectModalOpen,
  ] = useState(false);

  const openCrossbeamRedirectModal = () => {
    setIsCrossbeamRedirectModalOpen(true); // Toggle isOpen to false
  };

  const openInviteModal = () => {
    const { key, search, ...state } = location;
    const currentSearch = parse(search);
    // find the id from suggestedPartners based on the toggleFilterFieldname
    // if toggleFilterFieldname is empty, then use the first id:source pair
    const suggestionSources =
      !!toggleFilterFieldname &&
      DIRECTORY_COMPANY_SOURCE_TO_SUGGESTION_SOURCE[toggleFilterFieldname];
    const suggestedPartnerPair = suggestionSources
      ? Object.entries(suggestedPartners).find((value) =>
          suggestionSources.includes(value[1])
        )
      : Object.entries(suggestedPartners)[0];
    const suggestedPartnerId = suggestedPartnerPair?.[0];
    if (isPartnerOnReveal && isSuggestion) {
      if (suggestedPartnerId) {
        currentSearch["suggested-partner"] = "" + suggestedPartnerId; // put the id in the url
      }
      currentSearch[SentFromURL.SuggestedPartnerFromDirectory] = "true";
    }
    currentSearch["company-from-directory"] = id;
    if (!isPartnerOnReveal) {
      currentSearch["is-external"] = "true";
    }
    if (toggleFilterFieldname) {
      currentSearch["directory-tab"] = toggleFilterFieldname;
    }
    track(DirectoryEvent.clickedInvite, {
      ...(companyId && { invitedCompanyId: companyId }),
      ...(isSuggestion &&
        suggestedPartnerPair && { source: suggestedPartnerPair[1] }),
      ...(!isPartnerOnReveal && { isExternal: true }),
      ...(toggleFilterFieldname && { directoryTab: toggleFilterFieldname }),
    });
    history.push({
      ...state,
      search: stringify(currentSearch),
      hash: "#invite-partner",
    });
  };

  return (
    <div className={classes.cellContainer}>
      {!hideInviteBtn && (
        <>
          <Button
            label={isPartnerOnReveal ? i18n.connect : i18n.invite}
            LeftIcon={Plus}
            variant={isPartnerOnReveal ? "secondary" : "tertiary"}
            size="small"
            onClick={isPaidUser ? openInviteModal : openCrossbeamRedirectModal}
            data-testid="invite-btn"
          />
          <div>
            {isCrossbeamRedirectModalOpen && (
              <>
                <CrossbeamRedirectModal
                  onClose={() => setIsCrossbeamRedirectModalOpen(false)}
                />
              </>
            )}
          </div>
        </>
      )}
      {hasPartnership && (
        <T className={classes.invited}>
          <Check className={classes.partnerIcon} />
          <FormattedMessage
            {...(isActivePartnership ? i18n.partner : i18n.pending)}
          />
        </T>
      )}
    </div>
  );
};

export const isDirectoryPartnerCell = ({ fieldName }: CellProps) => {
  return fieldName === AvailableDirectoryColumns.partner;
};

// CSS

const useStyles = makeStyles()((theme) => ({
  cellContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  invited: {
    marginRight: theme.spacing(1),
  },
  partnerName: {
    borderRadius: 4,
  },
  partnerIcon: {
    with: theme.spacing(2),
    height: theme.spacing(2),
  },
}));

// I18N

const i18n = defineMessages({
  connect: {
    id: "Directory.DirectoryTile.connect",
    defaultMessage: "Connect",
  },
  invite: {
    id: "Directory.DirectoryTile.invite",
    defaultMessage: "Invite",
  },
  partner: {
    id: "Directory.DirectoryTile.partner",
    defaultMessage: "Partner",
  },
  pending: {
    id: "Directory.DirectoryTile.pending",
    defaultMessage: "Pending",
  },
});
