import { Box } from "@mui/material";
import { ArrowRight } from "components/icons";
import Button from "components/ui/Button";
import Popup from "components/ui/Popup";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { Link } from "react-router-dom";
import { ChatLink } from "screens/Frontoffice/shared/components/DialogWithSidePanel";

type Props = {
  onClose: () => void;
};

export const CrossbeamRedirectModal = ({ onClose }: Props) => {
  const { classes } = useStyles();
  const { profile } = useUserProfile();

  const companyName = profile.company.name;

  return (
    <Popup
      handleClose={onClose}
      hideActions
      isOpen
      maxWidth="sm"
      variant="secondary"
      width={520}
    >
      <Box p="8px" display="flex" flexDirection="column" gap="24px">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap="8px"
        >
          <img
            className={classes.name}
            src={"/images/crossbeamxreveal-logo.svg"}
            alt=""
          />
        </Box>
        <Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb="16px"
          >
            <T h2 bold>
              <FormattedHTMLMessage {...i18n.crossbeamTitle} />
            </T>
          </Box>
          <Box mb="24px">
            <T textAlign="center">
              <FormattedMessage {...i18n.mergerUpdate} />
            </T>
          </Box>
          <Box>
            <T textAlign="center">
              <FormattedMessage
                {...i18n.crossbeamSignUp}
                values={{ companyName }}
              />
            </T>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap="8px">
          <Link
            to={{
              pathname:
                "https://app.crossbeam.com/register?referrer='reveal.co'",
            }}
            rel="noopener noreferrer"
            target="_blank"
            data-testid={"link-to-crossbeam"}
          >
            <Button
              ellipsis
              label={i18n.createYourAccount}
              RightIcon={ArrowRight}
            />
          </Link>
          <Box display="flex" justifyContent="center" gap="4px">
            <T>
              <FormattedMessage {...i18n.needHelp} />
            </T>
            <ChatLink />
          </Box>
        </Box>
      </Box>
    </Popup>
  );
};

const i18n = defineMessages({
  crossbeamTitle: {
    id: "components.ui.CrossbeamRedirectModal.crossbeamTitle",
    defaultMessage: "Invite Partners On Crossbeam",
  },
  mergerUpdate: {
    id: "components.ui.CrossbeamRedirectModal.mergerUpdate",
    defaultMessage:
      "Reveal merged with Crossbeam to create the world's #1 platform for ecosystem led growth, and Reveal users will be migrating to Crossbeam by June 2025, which is why inviting is no longer available",
  },
  needHelp: {
    id: "components.ui.CrossbeamRedirectModal.needHelp",
    defaultMessage: "Need help?",
  },
  contactOurTeam: {
    id: "components.ui.CrossbeamRedirectModal.contactOurTeam",
    defaultMessage: "Contact our team.",
  },
  crossbeamSignUp: {
    id: "components.ui.CrossbeamRedirectModal.crossbeamSignUp",
    defaultMessage:
      "{companyName} already has a workspace on Crossbeam. Sign up and invite your new partners in your Crossbeam workspace.",
  },
  createYourAccount: {
    id: "Registration.goToCrossbeam",
    defaultMessage: "Go to Crossbeam",
  },
});

const useStyles = makeStyles()((theme) => ({
  formControl: {
    marginBottom: 20,
  },
  loader: {
    paddingRight: 9,
  },
  label: {
    color: "unset",
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    marginBottom: 6,
  },
  subtitle: {
    color: theme.palette.alpha750,
  },
  logoContainer: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    top: 24,
    left: 24,
  },
  logo: {
    width: 23,
    height: 26,
  },
  name: {
    width: 229,
    height: 28,
  },
}));
