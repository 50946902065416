import Grid from "@mui/material/Grid";
import {
  TagFilter,
  TagFilterType,
} from "components/ui/filters/filter/TagFilter";
import { QuickSearch } from "components/ui/QuickSearch";
import { T } from "components/ui/Typography";
import { partnership as partnershipDemo } from "data/demo";
import useHasPayingFeature from "hooks/useHasPayingFeature";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Partnership from "models/Partnership";
import Record from "models/Record";
import User from "models/User";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { setSearch } from "redux/dashboard/actions";
import { selectSearchValue } from "redux/dashboard/selectors";
import EmptyResults, {
  EmptySearchType,
} from "screens/Frontoffice/shared/components/EmptyResults";
import { PartnersEvent } from "tracking";

import InviteNewPartnerTile from "./InviteNewPartnerTile";
import PartnerOwnerFilter from "./PartnerOwnerFilter";
import PartnerTile, { PartnerTileSkeleton } from "./PartnerTile";
import StatusFilter, {
  partnershipsFilterLabel,
  PartnershipsFilterType,
} from "./StatusFilter";

type Props = {
  partnerships: Partnership[];
  loading?: boolean;
  hasSources: boolean;
  hasActiveOrCreatedPartnerships: boolean;
};

const Partnerships = ({ loading, partnerships }: Props) => {
  const { profile } = useUserProfile();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { track } = useSegment();
  const search = useSelector(selectSearchValue);
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const isPaidUser = profile.isPaidCustomer(payingFeatures);

  const [currentFilter, setCurrentFilter] = useState(
    PartnershipsFilterType.all
  );
  const [partnershipList, setPartnershipList] = useState<Partnership[]>([]);
  const [currentSearch, setCurrentSearch] = useState(search);
  const [selectedPartnerOwners, setSelectedPartnerOwners] = useState<Record[]>(
    []
  );
  const [selectedTags, setSelectedTags] = useState<Record[]>([]);
  const [tagFilterType, setTagFilterType] = useState<TagFilterType>("and");

  const partnershipsByPartnerOwners = useMemo(() => {
    return _.filter(partnerships, (partnership) => {
      if (selectedPartnerOwners.length === 0) return true;
      return selectedPartnerOwners.some(
        (po) => (partnership.partnershipOwner as User)?.id === po.id
      );
    }) as Partnership[];
  }, [partnerships, selectedPartnerOwners]);

  const partnershipsByTags = useMemo(() => {
    return _.filter(partnerships, (partnership) => {
      const parnershipTagIds: number[] = partnership.tags.map(
        (tag: Record) => tag.id
      );
      if (selectedTags.length === 0) return true;
      if (tagFilterType === "and") {
        return selectedTags.every((tag) => parnershipTagIds.includes(tag.id));
      } else {
        return selectedTags.some((tag) => parnershipTagIds.includes(tag.id));
      }
    }) as Partnership[];
  }, [partnerships, selectedTags, tagFilterType]);

  const partnershipsByFilters = useMemo(() => {
    if (partnershipsByPartnerOwners.length === 0) return partnershipsByTags;
    if (partnershipsByTags.length === 0 && selectedTags.length === 0)
      return partnershipsByPartnerOwners;
    return partnerships.filter(
      (partnership) =>
        partnershipsByPartnerOwners.includes(partnership) &&
        partnershipsByTags.includes(partnership)
    );
  }, [
    partnerships,
    partnershipsByPartnerOwners,
    partnershipsByTags,
    selectedTags.length,
  ]);

  const favorites = useMemo(() => {
    return _.filter(partnershipsByFilters, (partnership) =>
      partnership.isFavByUser(profile)
    ) as Partnership[];
  }, [partnershipsByFilters, profile]);

  const active = useMemo(() => {
    return _.filter(partnershipsByFilters, (partnership) =>
      partnership.isStatusActive(profile.company)
    ) as Partnership[];
  }, [partnershipsByFilters, profile]);

  const pendingCompany = useMemo(() => {
    return _.filter(partnershipsByFilters, (partnership) =>
      partnership.isStatusPendingByCompany(profile.company)
    ) as Partnership[];
  }, [partnershipsByFilters, profile]);

  const pendingPartner = useMemo(() => {
    return _.filter(partnershipsByFilters, (partnership) =>
      partnership.isStatusPendingByPartner(profile.company)
    ) as Partnership[];
  }, [partnershipsByFilters, profile]);

  const paused = useMemo(() => {
    return _.filter(partnershipsByFilters, (partnership) =>
      partnership.isPaused()
    ) as Partnership[];
  }, [partnershipsByFilters]);

  const offline = useMemo(() => {
    return _.filter(partnershipsByFilters, (partnership) =>
      partnership.isGhost()
    ) as Partnership[];
  }, [partnershipsByFilters]);

  const dispatchSearch = useMemo(
    () => _.debounce((search: string) => dispatch(setSearch(search)), 150),
    [dispatch]
  );

  const updateSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentSearch(event.target.value);
    trackSearch(event.target.value);
    dispatchSearch(event.target.value);
  };

  const trackSearch = useMemo(
    () =>
      _.debounce((search: string) => {
        track(PartnersEvent.searchedPartner, {
          keyword: search,
        });
      }, 3000),
    [track]
  );

  const clearSearch = () => {
    setSelectedTags([]);
    setSelectedPartnerOwners([]);
    setCurrentSearch("");
    dispatch(setSearch(""));
  };

  const filterFacets = {
    [PartnershipsFilterType.all]: partnershipsByFilters.length,
    [PartnershipsFilterType.favorites]: favorites.length,
    [PartnershipsFilterType.active]: active.length,
    [PartnershipsFilterType.pendingYou]: pendingCompany.length,
    [PartnershipsFilterType.pendingPartner]: pendingPartner.length,
    [PartnershipsFilterType.paused]: paused.length,
    [PartnershipsFilterType.offline]: offline.length,
  };

  const statusFilter = (
    <StatusFilter
      currentFilter={currentFilter}
      setCurrentFilter={setCurrentFilter}
      loading={loading}
      facets={filterFacets}
    />
  );

  const input = (
    <QuickSearch
      value={currentSearch}
      placeholder={intl.formatMessage(i18n.search)}
      onChange={updateSearch}
      onClear={clearSearch}
    />
  );

  const tagFilter = (
    <TagFilter
      value={selectedTags.map((tag) => String(tag.id))}
      onChange={setSelectedTags}
      toggleFilterValue={tagFilterType}
      onToggleFilterChange={setTagFilterType}
    />
  );

  const partnerOwnerFilter = (
    <PartnerOwnerFilter
      value={selectedPartnerOwners.map((po) => String(po.id))}
      onChange={setSelectedPartnerOwners}
    />
  );

  const isOnAllTab = currentFilter === PartnershipsFilterType.all;
  const isSearching = Boolean(search || selectedTags.length > 0);
  const hasGlobalResults = partnerships.length !== 0; // 'All' tab
  const hasFilteredResults = partnershipList.length !== 0; // Current tab
  const canInvitePartner = profile.canInvitePartner(payingFeatures);

  const hasLessThanFiveActivePartnerships =
    (_.filter(partnerships, (partnership) =>
      partnership.isStatusActive(profile.company)
    ) as Partnership[]).length < 5;
  const forceDemoPartnerDisplay = useHasPayingFeature(
    PayingFeature.ForceDemoPartnerDisplay,
    profile
  );
  const searchDemo = "demo".includes(search.toLowerCase()); // empty string returns true
  const showDemo =
    isOnAllTab &&
    searchDemo &&
    selectedTags.length === 0 &&
    (hasLessThanFiveActivePartnerships || forceDemoPartnerDisplay);
  const showEmptyResults = !showDemo && !hasFilteredResults; // We use the results of the current tab

  useEffect(() => {
    if (currentFilter === PartnershipsFilterType.all) {
      setPartnershipList(partnershipsByFilters);
    } else if (currentFilter === PartnershipsFilterType.favorites) {
      setPartnershipList(favorites);
    } else if (currentFilter === PartnershipsFilterType.active) {
      setPartnershipList(active);
    } else if (currentFilter === PartnershipsFilterType.pendingYou) {
      setPartnershipList(pendingCompany);
    } else if (currentFilter === PartnershipsFilterType.pendingPartner) {
      setPartnershipList(pendingPartner);
    } else if (currentFilter === PartnershipsFilterType.paused) {
      setPartnershipList(paused);
    } else if (currentFilter === PartnershipsFilterType.offline) {
      setPartnershipList(offline);
    } else {
      setPartnershipList([]);
    }
  }, [
    currentFilter,
    partnershipsByFilters,
    favorites,
    active,
    pendingCompany,
    pendingPartner,
    paused,
    offline,
  ]);

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid container item className={classes.headingContainer}>
        <Grid item>
          <T h2 bold>
            <FormattedMessage {...i18n.partners} />
          </T>
        </Grid>
        {canInvitePartner && (
          <Grid item className={classes.invitePartner}>
            <InviteNewPartnerTile isPaidUser={isPaidUser} />
          </Grid>
        )}
        <Grid item className={classes.search}>
          {input}
        </Grid>
      </Grid>
      <Grid container item spacing={2} className={classes.actionsContainer}>
        <Grid item>{statusFilter}</Grid>
        <Grid item className={classes.tagsAndPartnerOwnersFilters}>
          {tagFilter}
          {partnerOwnerFilter}
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        {loading ? (
          <>
            <Grid item width={"100%"}>
              <PartnerTileSkeleton />
            </Grid>
            <Grid item width={"100%"}>
              <PartnerTileSkeleton />
            </Grid>
            <Grid item width={"100%"}>
              <PartnerTileSkeleton />
            </Grid>
            <Grid item width={"100%"}>
              <PartnerTileSkeleton />
            </Grid>
          </>
        ) : showEmptyResults ? (
          // Show empty results message when current tab does not have results
          // "Update Filter to all" message will be displayed when
          // a. User is filtering but not searching or
          // b. User is filtering and his search retrieves results in the first tab or
          // c. User is filtering and has searched for the demo account
          // "Clear search" message will be displayed in other case, i.e when user is searching for a not existing account
          <EmptyResults
            search={search}
            handleOnClick={
              !isOnAllTab && (!isSearching || hasGlobalResults || searchDemo)
                ? () => setCurrentFilter(PartnershipsFilterType.all)
                : () => clearSearch()
            }
            searchType={EmptySearchType.partner}
            hasGlobalResults={
              hasGlobalResults || searchDemo // Force to show "Update Filter to all" message when searching for demo
            }
            filterName={
              !isOnAllTab
                ? partnershipsFilterLabel[currentFilter].defaultMessage
                : ""
            }
            selectedTags={selectedTags.map((tag) => tag.tagName).join(", ")}
          />
        ) : (
          <>
            {showDemo && (
              <Grid item width={"100%"}>
                <PartnerTile record={partnershipDemo} />
              </Grid>
            )}
            {partnershipList.map((partnership: Partnership) => (
              <Grid item key={partnership.id} width={"100%"}>
                <PartnerTile record={partnership} />
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Partnerships;

/// CSS

const useStyles = makeStyles()((theme) => ({
  headingContainer: {
    position: "relative",
    width: "100%",
  },
  invitePartner: {
    marginLeft: 16,
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  tagsAndPartnerOwnersFilters: {
    display: "flex",
  },
  search: { position: "absolute", right: 0 },
}));

/// I18N

const i18n = defineMessages({
  partners: {
    id: "Partners.Partnerships.Title",
    defaultMessage: "My Partners",
  },
  search: {
    id: "Partners.Partnerships.Search",
    defaultMessage: "Search Partners",
  },
});
