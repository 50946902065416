import { Plus } from "components/icons";
import Button, { Size } from "components/ui/Button";
import { CrossbeamRedirectModal } from "components/ui/CrossbeamRedirectModal";
import { useState } from "react";
import { defineMessages } from "react-intl";
import { useHistory } from "react-router-dom";

type Props = {
  isPaidUser: boolean;
  size?: Size;
};

const InviteNewPartnerTile = ({ isPaidUser, size }: Props) => {
  const history = useHistory();
  const [
    isCrossbeamRedirectModalOpen,
    setIsCrossbeamRedirectModalOpen,
  ] = useState(false);

  const openCrossbeamRedirectModal = () => {
    setIsCrossbeamRedirectModalOpen(true);
  };

  return (
    <>
      <Button
        label={i18n.inviteNewPartner}
        LeftIcon={Plus}
        onClick={
          isPaidUser
            ? () => history.push("/invite")
            : openCrossbeamRedirectModal
        }
        size={size}
        data-testid="invite-partner-btn"
      />
      <div>
        {isCrossbeamRedirectModalOpen && (
          <>
            <CrossbeamRedirectModal
              onClose={() => setIsCrossbeamRedirectModalOpen(false)}
            />
          </>
        )}
      </div>
    </>
  );
};

export default InviteNewPartnerTile;

/// I18N

export const i18n = defineMessages({
  inviteNewPartner: {
    id: "Partners.InviteNewPartnerTile.inviteNewPartner",
    defaultMessage: "Invite new partner",
  },
});
